import React, { useContext } from "react"

import { Container, Section } from "components/anti/grid/grid"
import { Breadcrumb } from "components/anti/breadcrumb/breadcrumb"
import { Button } from "components/anti/buttons/buttons"

//Load Context
import { LangContext } from "../../../../context/lang-context"

//Load assets
import graphic from "assets/img/common/shift-graphic/Frame.png"

export const WorkWithUsDetailsHeader = ({ data, slug, email }) => {
  console.log(email)
  const { lang } = useContext(LangContext)
  return (
    <Section className="work-with-us-details-header pt-3">
      <Container>
        <div className="graphic">
          <div className="circle-graphic" />
          <img src={graphic} className="img-graphic" />
        </div>
        <div className="breadcrumb-wrapper mb-md-5">
          {<Breadcrumb path={`/work-with-us/${slug}`} theme="light" />}
        </div>
        <h6>{data?.departement.name}</h6>
        <h1>{data?.jobTitle}</h1>
        <Button
          target={`mailto:${email}`}
          link={`mailto:${email}`}
          variant="primary"
          className="mt-3 mt-md-4"
        >
          {lang === "EN" ? "Apply Now" : "Daftar Sekarang"}
        </Button>
      </Container>
    </Section>
  )
}
