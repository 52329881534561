import React, { useContext } from "react"

import { Container, Section } from "components/anti/grid/grid"

//Load Context
import { LangContext } from "../../../../context/lang-context"

export const WorkWithUsDetailsContent = ({ data }) => {
  const { lang } = useContext(LangContext)
  return (
    <>
      <Section className="work-with-us-details-content">
        <Container>
          <div className="row row-5">
            <div className="col-lg-4 mb-md-down-4">
              <h6>{lang === "EN" ? "Description" : "Deskripsi"}</h6>
              <div dangerouslySetInnerHTML={{ __html: data?.description }} />
            </div>
            <div className="col-lg-8 mw-lg-750px">
              <h6>{lang === "EN" ? "Requirements" : "Persyaratan"}</h6>
              <div dangerouslySetInnerHTML={{ __html: data?.requirements }} />
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}
