import React, { useContext } from "react"
import SEO from "components/seo"
import Layout from "components/layout"
import { WorkWithUsDetailsHeader } from "components/shift/work-with-us/details/header"
import { WorkWithUsDetailsContent } from "components/shift/work-with-us/details/content"
import { WorkWithUsHiringProcess } from "components/shift/work-with-us/hiring-process"
import { AboutRequest } from "../components/shift/about/request"

//Load Context
import { LangContext } from "../context/lang-context"

const WorkWithUsDetailsPage = ({ pageContext }) => {
  const { lang } = useContext(LangContext)

  console.log(pageContext)

  const block =
    lang === "EN"
      ? pageContext?.data?.job
      : pageContext?.data?.translation?.job || pageContext?.data?.job

  const hiringProcess =
    lang === "EN"
      ? pageContext?.hiringProcess?.workWithUsPage
      : pageContext?.hiringProcess?.translation?.workWithUsPage ||
        pageContext?.hiringProcess?.workWithUsPage

  const request =
    lang === "EN"
      ? pageContext?.request?.aboutPage?.request
      : pageContext?.request?.translation?.aboutPage?.request ||
        pageContext?.request?.aboutPage?.request

  const slug = pageContext.data.slug
  const email = pageContext?.email?.workWithUsPage?.general?.applyUrl

  return (
    <Layout>
      <SEO title={pageContext.data.job.header.jobTitle} />
      <WorkWithUsDetailsHeader data={block.header} slug={slug} email={email} />
      <WorkWithUsDetailsContent data={block.content} />
      <div className="bg-light">
        <WorkWithUsHiringProcess data={hiringProcess.hiringProcess} />
      </div>
      <AboutRequest data={request} />
    </Layout>
  )
}

export default WorkWithUsDetailsPage
