import React, { useContext } from "react"
import { Container, Section } from "../../anti/grid/grid"
import { Slider } from "../../anti/slider/slider"

//Load Context
import { LangContext } from "../../../context/lang-context"
import { useScrollAnim } from "src/components/hooks/hooks"

export const WorkWithUsHiringProcess = ({ data }) => {
  const [trigger, anim] = useScrollAnim()
  const { lang } = useContext(LangContext)
  const block = data
  return (
    <Section forwardRef={trigger} className="hiring-process">
      <Container>
        <h2 className={`text-center title ${anim(1)}`}>
          {lang === "EN"
            ? "SHIFT Makers Hiring Process"
            : "Proses Perekrutan Pembawa Perubahan"}
        </h2>
        <Slider
          visibleInitial={true}
          visibleLgDown={true}
          visibleMdDown={true}
          visibleSmDown={true}
          showInitial={4}
          showXlDown={3.25}
          showLgDown={2.2}
          showMdDown={1.75}
          showSmDown={1.25}
          arrowsInitial={true}
          arrowsLgDown={false}
          arrowsMdDown={false}
          arrowsSmDown={false}
          className="slick-px-0"
        >
          {block.map((data, i) => {
            return (
              <div
                className={`position-relative ${
                  i % 2 === 0 ? "" : "wrap-2"
                } ${anim(2 + i)}`}
              >
                <div className="parallelogram">
                  <div className="skew-fix">
                    <div className="d-flex align-items-center flex-column">
                      <img
                        src={data?.image?.sourceUrl}
                        className="img-fluid img-content"
                        alt="Hiring"
                      />
                      <div className="px-5 px-lg-4 mr-3 mt-3">
                        <p className="p text-muted">{`STEP ${i + 1}`}</p>
                        <p className="p text-white">{data?.text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </Container>
    </Section>
  )
}
